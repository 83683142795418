import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "staff_ae_name", 
    "staff_ae_email", 
    "hidden_staff_ae_email", 
    "staff_dtp_name", 
    "staff_dtp_email", 
    "hidden_staff_dtp_email", 
    "staff_dtp_name2", 
    "staff_dtp_email2", 
    "hidden_staff_dtp_email2"
  ];

  connect() {
    console.log("connect")
    this.showCurrentEmail()
  }
  changeEmail(a) {
    this.showCurrentEmail()
  }


  showCurrentEmail() {
    let num = 0 // 「戻る」「編集」時にはセレクターが選択済なので「選択してください」がない→ずれる　対策
    if(this.data.get("selectedStaffAeName") != "") num = 1
    this.staff_ae_emailTargets.forEach((element, index) => {
      element.hidden = index != this.staff_ae_nameTarget.selectedIndex + num
      if(index == this.staff_ae_nameTarget.selectedIndex + num) {
        this.hidden_staff_ae_emailTarget.value = element.dataset.email
      }
    })
    // 必須でない項目は「選択してください」か「-」を表示するのでずれない
    this.staff_dtp_emailTargets.forEach((element, index) => {
      element.hidden = index != this.staff_dtp_nameTarget.selectedIndex
      if(index == this.staff_dtp_nameTarget.selectedIndex) {
        this.hidden_staff_dtp_emailTarget.value = element.dataset.email
      }
    })
    this.staff_dtp_email2Targets.forEach((element, index) => {
      element.hidden = index != this.staff_dtp_name2Target.selectedIndex
      if(index == this.staff_dtp_name2Target.selectedIndex) {
        this.hidden_staff_dtp_email2Target.value = element.dataset.email
      }
    })
  }
}
