import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["file"];

  initialize() {
    console.log('unattached-blobs initialize');
  }

  connect() {
    console.log('unattached-blobs connect')
    //console.log(this.fileTargets)
  }

  remove(event) {
    console.log(event)
    //setTimeout(() => document.getElementById(event.target.dataset.id).remove(), 1000)
    document.getElementById(event.target.dataset.id).remove()
    console.log(this.fileTargets)
    if(this.fileTargets.length == 0) {
      document.getElementById('unattached_blobs').classList.add('is-hidden')
    }
  }
}