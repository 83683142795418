import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    //console.log("modal controller")
  }
  delModal(e) {
    let toggle_el = document.getElementById('modal_' + e.target.dataset.id)
    toggle_el.classList.toggle('is-active')
  }
}
